import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Christmas movies are the best movies. How much do they make at the box office?
Show the power distribution curve with a vertical barchart.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id209295_box-office-revenue-of-the-most-successful-christmas-movies.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/qD6i9h66LbI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/6v6zvx8zjk?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`We built this one with React hooks because we can. Not a class-based component
in sight ✌️`}</p>
    <p>{`Styled components for styling, D3 for scales and data loading and parsing,
hooks to hook it all together.`}</p>
    <h2 {...{
      "id": "loading-data-with-react-hooks"
    }}>{`Loading data with React hooks`}</h2>
    <p>{`I looked around for a good data loading hook. None could be found. So we made
our own 💪`}</p>
    <p>{`Not that hard as it turns out. You need a dash of `}<inlineCode parentName="p">{`useState`}</inlineCode>{` to save the data
you load, a bit of `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` to run data loading on component mount aaaand
... that's it. Goes in your `}<inlineCode parentName="p">{`App`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function App() {
  const [data, setData] = useState(null);

  useEffect(
    () => {
      d3.tsv("/data.tsv", d => {
        const year = Number(d.movie.match(/\\((\\d+)\\)/)[1]);
        return {
          movie: d.movie.replace(/\\(\\d+\\)/, ""),
          year: year,
          per_year: Number(d.box_office) / (2018 - year),
          box_office: Number(d.box_office)
        };
      }).then(setData);
    },
    [!data]
  );
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook takes a default value, and always returns current state -
`}<inlineCode parentName="p">{`data`}</inlineCode>{` - and a setter - `}<inlineCode parentName="p">{`setData`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` runs our function on every component render. After committing to
the DOM, I believe. We use `}<inlineCode parentName="p">{`d3.tsv`}</inlineCode>{` to load and parse our christmas movie
dataset, use a parsing function to transform each row into an object with all
the info we need, then call `}<inlineCode parentName="p">{`setData`}</inlineCode>{` when he have it.`}</p>
    <p>{`Each datapoint holds`}</p>
    <ul>
      <li parentName="ul">{`a `}<inlineCode parentName="li">{`movie`}</inlineCode>{` name`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`year`}</inlineCode>{` a movie was produced parsed from the movie name with a regex`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`per_year`}</inlineCode>{` revenue of the movie as a fraction`}</li>
      <li parentName="ul">{`the total `}<inlineCode parentName="li">{`box_office`}</inlineCode>{` revenue`}</li>
    </ul>
    <h2 {...{
      "id": "switch-display-modes-with-react-hooks"
    }}>{`Switch display modes with React hooks`}</h2>
    <p>{`Movie box office revenue follows a pretty clear power law distribution. The
highest grossing movie or two make a lot more than the next best. Which makes
way more than next one down the list, etc.`}</p>
    <p>{`But how does age factor into this?`}</p>
    <p>{`Home Alone has had 28 years to make its revenue. Daddy's Home 2 is only a year
old.`}</p>
    <p>{`I decided to add a button to switch modes. From total `}<inlineCode parentName="p">{`box_office`}</inlineCode>{` to
`}<inlineCode parentName="p">{`per_year`}</inlineCode>{` revenue. And boy does it change the story. Altho maybe I'm being
unfair because how long are theater runs anyway? 🤔`}</p>
    <p>{`Driving that logic with React hooks looks like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [perYear, setPerYear] = useState(false)
const valueFunction = perYear ? d => d.per_year : d => d.box_office

// ...

<Button onClick={() => setPerYear(!perYear)}>
  {perYear ? "Show Total Box Office" : "Show Box Office Per Year"}
</Button>
`}</code></pre>
    <p>{`A `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook gives us current state and a setter. We use the state,
`}<inlineCode parentName="p">{`perYear`}</inlineCode>{`, to define a value accessor function, and a butto's `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` method
to toggle the value.`}</p>
    <p>{`We're going to use that value accessor to render our graph. Makes the switch
feel seamless.`}</p>
    <h2 {...{
      "id": "render"
    }}>{`Render`}</h2>
    <p>{`First you need this bit in your `}<inlineCode parentName="p">{`App`}</inlineCode>{` function. It renders `}<inlineCode parentName="p">{`<VerticalBarchart>`}</inlineCode>{`
in an SVG, if `}<inlineCode parentName="p">{`data`}</inlineCode>{` exists.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Svg width="800" height="600" showKevin={perYear}>
  {data && (
    <VerticalBarchart
      data={data}
      width={600}
      height={600}
      value={valueFunction}
    />
  )}
</Svg>
`}</code></pre>
    <p>{`That `}<inlineCode parentName="p">{`data && ...`}</inlineCode>{` is a common trick. The return value of `}<inlineCode parentName="p">{`true && something`}</inlineCode>{`
is something, return value of `}<inlineCode parentName="p">{`false && something`}</inlineCode>{` is nothing. Means when
`}<inlineCode parentName="p">{`data`}</inlineCode>{` is defined, we render, otherwise we don't.`}</p>
    <p>{`Oh and `}<inlineCode parentName="p">{`Svg`}</inlineCode>{` is a styled SVG component. Gets a nice gif background when
`}<inlineCode parentName="p">{`showKevin`}</inlineCode>{` is set to true 😛`}</p>
    <p>{`The `}<inlineCode parentName="p">{`VerticalBarchart`}</inlineCode>{` itself is a functional component. We said no classes
right?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const VerticalBarchart = ({ data, width, height, value }) => {
  const yScale = d3
    .scaleBand()
    .paddingInner(0.1)
    .domain(data.map(d => d.movie))
    .range([0, height]);
  const widthScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, value)])
    .range([0, width]);

  return (
    <g>
      {data.map(d => (
        <React.Fragment key={d.movie}>
          <Bar
            x={0}
            y={yScale(d.movie)}
            height={yScale.bandwidth()}
            width={widthScale(value(d))}
          />
          <Label x={10} y={yScale(d.movie) + yScale.bandwidth() / 2}>
            {d.movie}
          </Label>
        </React.Fragment>
      ))}
    </g>
  );
};
`}</code></pre>
    <p>{`We can define our D3 scales right in the render function. Means we re-define
them from scratch on every render and sometimes that's okay. Particularly when
data is small and calculating domains and ranges is easy.`}</p>
    <p>{`Once we have a `}<inlineCode parentName="p">{`scaleBand`}</inlineCode>{` for the vertical axis and a `}<inlineCode parentName="p">{`scaleLinear`}</inlineCode>{` for
widths, it's a matter of iterating over our data and rendering styled `}<inlineCode parentName="p">{`<Bar>`}</inlineCode>{`
and `}<inlineCode parentName="p">{`<Label>`}</inlineCode>{` components.`}</p>
    <p>{`Notice that we use the `}<inlineCode parentName="p">{`value`}</inlineCode>{` accessor function every time we need the value
of a datapoint. To find the max value for our domain and to grab each
individual width.`}</p>
    <p>{`Makes our chart automatically adapt to flicking that `}<inlineCode parentName="p">{`perYear`}</inlineCode>{` toggle 👌`}</p>
    <video src="https://i.imgur.com/hJaR7e8.mp4" autoPlay muted loop />
    <p>{`That smooth width transition effect? That's just CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Bar = styled.rect\`
  fill: green;
  transition: width 500ms;
\`;
`}</code></pre>
    <p>{`React hooks really do make life easy 🎣`}</p>
    <h2 {...{
      "id": "what-you-learned-today"
    }}>{`What you learned today`}</h2>
    <ul>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`useState`}</inlineCode>{` React hook`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`useEffect`}</inlineCode>{` React hook`}</li>
      <li parentName="ul">{`that it's okay to define D3 stuff in the render method`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      